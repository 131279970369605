const basicTabs = {
    init() {
        document.querySelectorAll('[data-tab-trigger]').forEach(trigger => {
            trigger.addEventListener('click', this.toggle.bind(this));
        })
    },

    toggle(e) {
        const tabGroupName = e.currentTarget.getAttribute('data-tab-trigger');
        const target = e.currentTarget.getAttribute('href');

        e.preventDefault();

        // Close
        document.querySelectorAll(`[data-tab-trigger="${tabGroupName}"]`)
            .forEach(item => item.classList.remove('active'));
        document.querySelectorAll(`[data-tab-content="${tabGroupName}"]`)
            .forEach(item => item.classList.remove('show', 'active'));

        // Open
        document.querySelectorAll(`[data-tab-trigger="${tabGroupName}"][href="${target}"]`)
            .forEach(item => item.classList.add('active'));
        document.querySelectorAll(`[data-tab-content="${tabGroupName}"]${target}`)
            .forEach(item => item.classList.add('show', 'active'));
    }
};

export default basicTabs;
