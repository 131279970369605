const SimpleDropdown = {
    init() {
        this.triggers = document.querySelectorAll('[data-drop-trigger]');
        this.dropdowns = document.querySelectorAll('[data-drop]');

        if (this.triggers) {
            this.triggers.forEach(trigger => trigger.addEventListener('click', this.open.bind(this)));
            window.addEventListener('click', this.close.bind(this));

            this.dropdowns.forEach(drop => {
                drop.addEventListener('click', e => {
                    e.stopPropagation();
                })
            })
        }
    },

    open(e) {
        const attr = e.currentTarget.getAttribute('data-drop-trigger');
        const current = document.querySelectorAll(`[data-drop="${attr}"]`);

        e.preventDefault();

        if (current && current[0].classList.contains('d-none')) {
            e.stopPropagation();
            this.close();
            current.forEach(box => {
                box.classList.remove('d-none')
            });
        } else {
            this.close();
        }
    },

    close() {
        this.dropdowns.forEach(item => item.classList.add('d-none'));
    }
};

export default SimpleDropdown;
