import '../../../vendor/sylius/bootstrap-theme/assets/js/index';
import './scss/index.scss';
import '../../../vendor/sylius/bootstrap-theme/assets/media/sylius-logo.png';
import '../../../vendor/sylius/bootstrap-theme/assets/js/fontawesome';
import simpleDropdown from './js/dropdown';
import basicTabs from './js/tabs';
import Swiper from 'swiper';
import GLightbox from 'glightbox';

window.addEventListener('DOMContentLoaded', () => {
  // Dropdown
  simpleDropdown.init();

  // Tabs
  basicTabs.init();

  // Nav toggle
  const navToggle = document.querySelector('.mega-toggle');
  if (navToggle) {
    navToggle.addEventListener('click', e => {
      document.querySelector('body').classList.toggle('mega--open');
      document.querySelector('.mega').classList.toggle('mega--open');
      document.querySelector('.hamburger').classList.toggle('is-active');
    });
  }

  // Swiper
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  // Class switcher
  document.querySelectorAll('[data-switch-target]').forEach((trigger) => {
    const target = trigger.getAttribute('data-switch-target');
    const className = trigger.getAttribute('data-switch-class');

    trigger.addEventListener('click', (e) => {
      document.querySelectorAll(target).forEach((item) => {
        item.classList.toggle(className);
      });
    });
  });

  // Select
  document.querySelectorAll('[data-select-link]').forEach((select) => {
    select.addEventListener('change', (e) => {
      window.location.href = e.currentTarget.value;
    });
  });

  const glightbox = GLightbox({ selector: '.glightbox' });
});
